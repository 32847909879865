import { useQuery, useManualQuery } from 'graphql-hooks';

const optinToCatalogQuery = `
    mutation OptinToCatalog($productId: String!, $catalogProductId: String!) {
        optinToCatalog(productId: $productId, catalogProductId: $catalogProductId) {
            id
            errors
        }
  }
`;

export const useOptinToCatalog = (productId, catalogProductId, variationId) => {
    const { data, loading, error } = useQuery(optinToCatalogQuery, { variables: { productId, catalogProductId } });
    const errors = (error ? [error] : (data && data.optinToCatalog && data.optinToCatalog.errors) || []).filter(error => error);
    return { data, loading, errors: errors?.length ? errors : null };
}

export const useOptinToCatalogManual = () => {
    const [optinToCatalog, { data, loading, error }] = useManualQuery(optinToCatalogQuery);
    const errors = (error ? [error] : (data && data.optinToCatalog && data.optinToCatalog.errors) || []).filter(error => error);
    return { optinToCatalog, data, loading, errors: errors.length > 0 ? errors : null };
}

export {optinToCatalogQuery}