import CheckCircleIcon from './utils/iconComponents/CheckCircleIcon';

const PostModalAfterPublish = ({ permalink, onClose }) => {
  return (
    <div className='w-full flex justify-center'>
      <div className='w-[80%] shadow rounded-md p-4'>
        <div className='pt-2 pr-0 pb-1 pl-0 w-full flex justify-center'>
          <span className='flex text-primary-700 w-[40px]'>
            <CheckCircleIcon />
          </span>
        </div>
        <div className='pt-2 pr-0 pb-4 pl-0'>
          <p className='flex justify-center text-lg font-bold'>
            El producto ha sido publicado con exito!
          </p>
          <span className='w-full flex justify-center text-primary-700'>
            <a href={permalink} target='_blank' onClick={onClose}>
              Ir al articulo
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PostModalAfterPublish;
