import React, { useEffect, useState } from 'react';
import { useSearchElegibleProductsManual } from '../hooks/useSearchElegibleProducts';
import { useBottomReached } from '../hooks/useBottomReached';
import { useGetItemInfoManual } from 'hooks/useGetItemInfo';
import { CompareElegibleProductModal } from './CompareElegibleProductModal';
import _ from 'lodash';
import { SearchBox } from './SearchBox';
import ArticleIcon from './utils/iconComponents/ArticleIcon';
import { ExclamationIcon } from './MlCatalogProductList/ExclamationIcon';
import { SpinnerIcon } from './MlCatalogProductList/SpinnerIcon';
import LinkArrow from './images/LinkArrow.svg';
import { Scrollbar } from 'react-scrollbars-custom';
import { useAddAmazonGtinsToMlItem } from 'hooks/useAddAmazonGtinsToMlItem';

export const ElegibleProductsList = ({
  query: queryStr,
  setSelectedTabNumber,
}) => {
  const [query, setQuery] = useState(queryStr);
  const { searchElegibleProducts, loading, error, products, total } =
    useSearchElegibleProductsManual();
  const [page, setPage] = useState(0);
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const bottomOfScreenReached = useBottomReached();
  const pageSize = 20;
  const lastPage = Math.floor(total / pageSize);
  const [reachedTable, setReachedTable] = useState(false);
  const [itemInfo, setItemInfo] = useState({});
  const [idxSelectedProduct, setIdxSelectedProduct] = useState('');

  const addAmazonGtinsToMlItem = useAddAmazonGtinsToMlItem();

  const { getItemInfo } = useGetItemInfoManual();

  useEffect(() => {
    setPage(0);
    setLoadedProducts([]);
    searchElegibleProducts({
      variables: { query, offset: page * pageSize, limit: pageSize },
    });
  }, [query]);

  useEffect(() => {
    searchElegibleProducts({
      variables: {
        query,
        offset: page * pageSize,
        limit: pageSize,
      },
    });
  }, [page]);

  useEffect(() => {
    if (products) {
      setLoadedProducts((loadedProducts) => loadedProducts.concat(products));
    }
  }, [products]);

  useEffect(() => {
    if (
      (bottomOfScreenReached || reachedTable) &&
      products &&
      !loading &&
      page < lastPage
    ) {
      setReachedTable(false);
      setPage(page + 1);
    }
  }, [bottomOfScreenReached, reachedTable]);

  const onProductSelected = async (product, idx) => {
    const dataInfo = await getItemInfo({
      variables: {
        itemId: product.details.id,
      },
    });

    if (dataInfo) {
      // console.info('atributos del item...', dataInfo?.data?.getItemInfoQuery?.attributes);
      const gtinValue = dataInfo?.data?.getItemInfoQuery?.attributes?.filter(
        (attr) => attr.name === 'GTIN'
      );
      // console.info('gtinValue...', gtinValue);

      if (gtinValue.length > 0) {
        dataInfo.data.getItemInfoQuery.gtinValue = gtinValue[0].value;
      } else {
        const gtinResponse = await addAmazonGtinsToMlItem({
          variables: {
            asin: dataInfo?.data?.getItemInfoQuery.seller_custom_field,
            mlItemId: product.details.id,
          },
        });

        dataInfo.data.getItemInfoQuery.gtinValue = gtinResponse.data.addAmazonGtinsToMlItem;
      }

      setItemInfo(dataInfo?.data?.getItemInfoQuery || {});
    }
    setIdxSelectedProduct(idx);
    setSelectedProduct(product);
  };

  const handlerScroll = (e) => {
    if (e.contentScrollHeight - e.scrollTop === e.clientHeight) {
      setPage(page + 1);
    }
  };

  return (
    <div className='flex flex-col h-full'>
      {/* Top section with tabs and search */}
      <div className='w-full h-[60px] flex justify-between mb-2'>
        <div className='w-[3%] h-[60px] flex justify-start p-2 items-center'>
          <ArticleIcon />
        </div>
        <div className='w-[57%] h-[60px] flex justify-start p-[10px]'>
          <div className='w-full h-[40px] flex gap gap-4'>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(0)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Administrar artículos
              </span>
            </div>
            <div className='h-[36px] rounded-md content-center'>
              <span className='px-[12px] py-[8px] text-sm font-semibold text-primary-700 '>
                Artículos elegibles
              </span>
            </div>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(2)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Estrategia de optimización
              </span>
            </div>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(3)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Todos los artículos
              </span>
            </div>
          </div>
        </div>
        <div className='w-[40%] h-[60px] flex justify-end'>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-end gap-3 inline-flex'>
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='h-[44px] self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal"></div>
                  <SearchBox
                    query={query}
                    onQueryChanged={_.debounce(setQuery, 1000)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Table section */}
      <div className='flex flex-col flex-grow overflow-hidden'>
        {/* Table header */}
        <div className='w-full px-6 py-3 bg-gray-50 shadow border border-gray-200 rounded-t-xl'>
          <div className='flex items-center'>
            <div className="w-24 px-2 text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]">
              Imagen
            </div>
            <div className='flex-1 text-slate-600 text-xs font-medium'>
              Titulo
            </div>
            <div className='w-32 text-slate-600 text-xs font-medium flex justify-center'>
              ID
            </div>
            <div className='w-32 text-slate-600 text-xs font-medium flex justify-center'>
              SKU
            </div>
            <div className='w-32 text-slate-600 text-xs font-medium flex justify-center'>
              ID Catalogo
            </div>
            <div className='w-24 text-slate-600 text-xs font-medium flex justify-center'>
              Precio
            </div>
            <div className='w-24 text-slate-600 text-xs font-medium flex justify-center'>
              Disponibles
            </div>
            <div className='w-24 text-slate-600 text-xs font-medium flex justify-center'>
              Vendidos
            </div>
            <div className='w-40 text-slate-600 text-xs font-medium flex justify-center'></div>
          </div>
        </div>

        {/* Table body with scroll */}
        <div className='flex-grow bg-white rounded-b-xl shadow border border-t-0 border-gray-200'>
          <Scrollbar
            onScroll={handlerScroll}
            style={{ width: '100%', height: '100%' }}
            trackYProps={{
              style: {
                width: '8px',
                background: '#f1f1f1',
                borderRadius: '4px',
              },
            }}
            thumbYProps={{
              style: {
                background: '#c1c1c1',
                borderRadius: '4px',
              },
            }}
          >
            {loadedProducts.map((product, key) => (
              <div
                key={key}
                className='flex items-center pl-0 pr-6 py-4 border-b border-gray-200 hover:bg-gray-50'
              >
                <div className='w-24 flex items-center justify-center mr-4'>
                  <img
                    className='w-[75px] h-14 object-cover rounded-[10px] border border-gray-200'
                    src={product.details.thumbnail}
                    alt={product.details.title}
                  />
                </div>
                <div className='flex-1 text-sm text-gray-900'>
                  <div>{product.details.title}</div>
                </div>
                <div className='w-32 text-xs text-gray-900 flex justify-center'>
                  <a
                    href={product.details.permalink}
                    target='_blank'
                    rel='noreferrer'
                    className='text-primary-700 flex items-center'
                  >
                    <span>{product.details.id}</span>
                    <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
                  </a>
                </div>
                <div className='w-32 text-xs text-gray-900 flex justify-center'>
                  {product?.details?.seller_custom_field && (
                    <a
                      href={`https://amazon.com/dp/${product.details.seller_custom_field}`}
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary-700 flex items-center'
                    >
                      <span>{product.details.seller_custom_field}</span>
                      <img
                        src={LinkArrow}
                        alt='Arrow'
                        className='ml-1 w-2 h-2'
                      />
                    </a>
                  )}
                </div>
                <div className='w-32 text-xs text-gray-900 flex justify-center'>
                  {product?.details?.catalog_product_id && (
                    <a
                      href={`https://mercadolibre.com.uy/p/${product.details.catalog_product_id}`}
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary-700 flex items-center'
                    >
                      <span>{product.details.catalog_product_id}</span>
                      <img
                        src={LinkArrow}
                        alt='Arrow'
                        className='ml-1 w-2 h-2'
                      />
                    </a>
                  )}
                </div>
                <div className='w-24 text-right text-xs text-gray-900 flex justify-center'>
                  {product.details.currency_id} {product.details.price}
                </div>
                <div className='w-24 text-center text-xs text-gray-900 flex justify-center'>
                  {product.details.available_quantity}
                </div>
                <div className='w-24 text-center text-xs text-gray-900 flex justify-center'>
                  {product.details.sold_quantity}
                </div>
                <div className='text-right px-2 py-1 bg-gray-100 rounded-2xl flex justify-center'>
                  <button
                    onClick={() => onProductSelected(product, key)}
                    className='text-sm text-primary-700 flex items-center justify-end'
                  >
                    <span>Convertir a catálogo</span>
                    <img src={LinkArrow} alt='Arrow' className='ml-1 w-3 h-3' />
                  </button>
                </div>
              </div>
            ))}
            {loading && (
              <div className='p-4'>
                <SpinnerIcon />
              </div>
            )}
            {error && (
              <div className='p-4'>
                <ExclamationIcon message={error.httpError?.statusText} />
              </div>
            )}
          </Scrollbar>
        </div>
      </div>

      {/* CompareElegibleProductModal */}
      {selectedProduct && (
        <CompareElegibleProductModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
          itemInfo={itemInfo}
          idxSelectedProduct={idxSelectedProduct}
          setNewListProduct={(newListProduct) => setLoadedProducts(newListProduct)}
          loadedProducts={loadedProducts}
        />
      )}
    </div>
  );
};
