import React, { useEffect, useState } from 'react';
import { TreeSelector } from 'components/TreeSelector/TreeSelector';
import { useGetUserImportCategories } from 'hooks/useGetUserImportCategories';
import { useSetUserImportCategories } from 'hooks/useSetUserImportCategories';
import { useGetImportableItemsByAmazonClassificationsManual } from 'hooks/useGetImportableItemsByAmazonClassifications';
import { useTree } from 'contexts/TreeSelectionContext';
import { ImportSelectionEditor } from 'components/ImportSelectionEditor/ImportSelectionEditor';
import { useQueueAmazonClassificationImportManual } from 'hooks/useQueueAmazonClassificationImport';
import { ImportQueue } from 'components/ImportQueue/ImportQueue';

interface CategorySelectorProps {
  onSaveSettings?: () => Promise<void>;
  isValidated?: boolean;
  isValidatedKg?: boolean;
}

export const CategorySelector: React.FC<CategorySelectorProps> = ({
  onSaveSettings,
  isValidated,
  isValidatedKg,
}) => {
  const [showTreeSelector, setShowTreeSelector] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  
  // Hooks
  const { getUserImportCategories, loading: loadingCategories, error: errorCategories, data } = useGetUserImportCategories();
  const { setUserImportCategories, selectionId } = useSetUserImportCategories();
  const { loading: loadingItems, error: errorItems, data: dataItems } = 
    useGetImportableItemsByAmazonClassificationsManual();
  const { dispatch, state } = useTree();
  const [selectedItems, setSelectedItems] = useState<Array<{classificationId: string; quantity: number}>>([]);
  const { queueImportManual, loading: savingImportQuantities, error } = useQueueAmazonClassificationImportManual();

  useEffect(() => {
    setShowTreeSelector(false);
    setShowConfirmation(false);
    setSelectedItems([]);
    setExpandedNodes([]);
    dispatch({
      type: 'CLOSE_ALL_LEVELS'
    });
  }, []);

  useEffect(() => {
    if (data?.getUserImportCategories?.classificationsIds?.length > 0) {
      dispatch({
        type: 'SELECT_MULTIPLE',
        nodeIds: data.getUserImportCategories.classificationsIds
      });
    }
  }, [data, dispatch]);

  const handleSave = async (): Promise<void> => {
    try {
      const selectedNodes = [...state.selectedNodes];
      const result = await setUserImportCategories(selectedNodes);
      
      if (!result?.data?.setUserImportCategories?.selectionId) {
        console.error('No se pudo obtener el ID de selección');
        return;
      }

      if (onSaveSettings) {
        await onSaveSettings();
      }

      await getUserImportCategories();
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error saving categories:', error);
    }
  };

  const handleBack = (): void => {
    setShowConfirmation(false);
  };

  const handleNodeToggle = (nodeId: string, isExpanded: boolean) => {
    setExpandedNodes(prev => 
      isExpanded 
        ? [...prev, nodeId]
        : prev.filter(id => id !== nodeId)
    );
  };

  const handleQueueImport = async () => {
    await queueImportManual({
      variables: {
        quantities: selectedItems
      }
    });
    setShowTreeSelector(false);
    setShowConfirmation(false);
    setExpandedNodes([]);
    setSelectedItems([]);
  };

  const handleSelectionChange = (selectedItems: Array<{classificationId: string; quantity: number}>) => {
    setSelectedItems(selectedItems);
  };

  const handleCancel = () => {
    setShowTreeSelector(false);
    setShowConfirmation(false);
    setSelectedItems([]);
    setExpandedNodes([]);
    dispatch({
      type: 'UNSELECT_ALL'
    });
  };

  if (errorCategories || errorItems) {
    console.error('Error loading data:', errorCategories || errorItems);
    return null;
  }

  if (!showTreeSelector) {
    return <ImportQueue onNewImport={() => {
      setShowTreeSelector(true);
      setExpandedNodes([]);
      setSelectedItems([]);
    }} />;
  }

  return (
    <div className="mb-2">
      <div className="flex w-full flex-col">
        <div className="sm:col-span-3 w-full">
          <div className="flex w-full">
            <div className={`w-full`}>
              <div className="overflow-y-auto" style={{ display: showConfirmation ? 'none' : 'block' }}>
                <div className="px-2 pb-4 text-gray-700 text-xl">Selecciona las categorías de Amazon que deseas importar</div>
                <TreeSelector 
                  nodeId="null" 
                  expandedNodes={expandedNodes}
                  onNodeToggle={handleNodeToggle}
                  itemsPerClassification={dataItems?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification}
                />
              </div>
              {showConfirmation && (
                <ImportSelectionEditor selectionId={selectionId || ''} onSelectionChange={handleSelectionChange} />
              )}
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 flex justify-end gap-2">
          {showConfirmation ? (
            <>
              <button
                onClick={handleBack}
                className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600"
              >
                Anterior
              </button>
              <button
                onClick={handleQueueImport}
                className="bg-primary-600 text-white px-6 py-2 rounded hover:bg-primary-700 disabled:opacity-50"
                disabled={loadingCategories || loadingItems || savingImportQuantities}
              >
                Importar
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600"
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-primary-600 text-white px-6 py-2 rounded hover:bg-primary-700 disabled:opacity-50"
                disabled={loadingCategories || loadingItems}
              >
                {loadingCategories || loadingItems ? 'Guardando...' : 'Siguiente'}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}; 