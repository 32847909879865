import { useQuery, useManualQuery } from 'graphql-hooks';

const GetElegibleCatalogSuggestionsQueryByItem = `
    query GetElegibleCatalogSuggestionByItem($itemId: String!) {
        getElegibleCatalogSuggestionByItem(itemId: $itemId) {
            id
            name
            permalink
            attributes {
                id
                name
                value_name
            }
            pictures {
                url
            }
            reasons
            points
        }
    }
`

export const useGetElegibleCatalogSuggestionsByItem = (itemId) => {
    const { loading, error, data } = useQuery(GetElegibleCatalogSuggestionsQueryByItem, {
        variables: { itemId }
    });
    return { loading, error, data }; 
}

export const useGetElegibleCatalogSuggestionsByItemManual = () => {
    const [getElegibleCatalogSuggestionByItem, { loading, error, data }] = useManualQuery(GetElegibleCatalogSuggestionsQueryByItem);
    return { getElegibleCatalogSuggestionByItem, loading, error, data };
}

export {GetElegibleCatalogSuggestionsQueryByItem}